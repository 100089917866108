import { config } from './config';

/**
 * Initializes the DOM by dynamically adding <link> and <meta> elements to the <head> of the document.
 *
 * Responsibilities:
 * - Adds <link rel="preconnect"> elements for URLs defined in environment variables to optimize resource loading.
 * - Ensures meta tags are present in document. If not, it appends a <meta name="description"> and
 *   a <meta name="widget-type"> tag to the document head, with the provided `widgetType` parameter.
 *
 * @param {WidgetType} widgetType - Specifies the type of widget being initialized.
 *                                  Used both for <meta name="widget-type"> to identify the source of booking
 *                                  in the `booking-create` event and for <meta name="description">
 *                                  to provide a summary of the page content for search engines and social media.
 */
export const initDom = (widgetType: WidgetType) => {
  const firstChild = document.head.firstChild;

  const metaTags = [
    {
      name: 'description',
      content: `Guest widget: Discover amazing offers and book your next experience with our easy-to-use ${widgetType}.`,
    },
    {
      name: 'widget-type',
      content: widgetType,
    },
  ];

  metaTags.forEach(({ name, content }) => {
    if (!document.querySelector(`meta[name=${name}]`)) {
      const meta = document.createElement('meta');
      meta.setAttribute('name', name);
      meta.setAttribute('content', content);
      document.head.insertBefore(meta, firstChild);
    }
  });

  const linkUrls = [
    import.meta.env.VITE_GUEST_EXPERIENCE_URL,
    import.meta.env.VITE_EDITOR_BACKEND_URL,
    import.meta.env.VITE_BASE_URL,
    config.sharedResourcesBaseUrl,
  ];

  linkUrls.forEach((url) => {
    if (url) {
      const link = document.createElement('link');
      link.setAttribute('rel', 'preconnect');
      link.setAttribute('href', url);
      document.head.insertBefore(link, firstChild);
    }
  });
};

export enum WidgetType {
  CATALOG = 'catalog-widget',
  PRODUCT = 'product-widget',
  BOOKING = 'booking-widget',
  BUTTON = 'button-widget',
  CALENDAR = 'calendar-widget',
}
