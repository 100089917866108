import { register } from '@guest-widgets/core/src/register';
import { initDom, WidgetType } from '@guest-widgets/shared/src/initDom';
import { widgetTagNames } from '@guest-widgets/shared/src/config';
import { hasShadowDom } from '@guest-widgets/shared/src/utils/hasShadowDom';
import { checkLoadedAndRegister } from '@guest-widgets/shared/src/utils/singleLoad';

(async () => {
  if (checkLoadedAndRegister('catalog')) return;

  const { CatalogWidgetWebComponent } = await import('./CatalogWidgetWebComponent');

  register(
    CatalogWidgetWebComponent,
    widgetTagNames.catalog,
    [
      'customer-code',
      'locale',
      'disable-features',
      'enable-features',
      'styles-template-id',
      // This is an option set the viewport to something different than the window to be able to simulate different
      // device sizes without using the dev tools. Rendering the widget in an iframe would be the alternative
      'viewport-id',
      // TODO temporarily solution, this should be retrieved over the editor settings in the future
      'category-filter',
      'location-filter',
      'widget-id',
      'configuration',
      'sub-id',
      'data-disable-font',
    ],
    hasShadowDom
  );

  initDom(WidgetType.CATALOG);
})();
